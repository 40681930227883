<dd-spinner *ngIf="isLoading"></dd-spinner>
<div class="container-fluid common-deploy-job-container" #djConfigContainer>

    <ng-container *ngIf="deployConfig">
        <div class="pos-relative">
            <span class="title-span fw-bold fs-16px">{{'LOCALIZATION.DEPLOYMENT_JOB.CONFIGURATION_CHANGE_TEXT' |
                translate}}</span>

            <div class="d-flex flex-column config-container">
                <dd-spinner *ngIf="isReloadDJConfig"></dd-spinner>
                <div class="row no-gutters" *ngIf="!isReloadDJConfig">
                    <table aria-describedby="tableHd" class="table example-full-width mt-3">
                        <thead>
                            <th scope="col" class="pl-2">{{'LOCALIZATION.AUDIT.MODULE' | translate}}</th>
                            <th scope="col" class="pl-2">{{'LOCALIZATION.AUDIT.TIMESTAMP' | translate}}</th>
                            <th scope="col" class="pl-2">{{'LOCALIZATION.AUDIT.ACTION' | translate}}</th>
                        </thead>
                        <tbody>
                            <tr [ngClass]="{'border-bottom': !lastRow}"
                                *ngFor="let log of deployConfig.trails.items, let i = index, last as lastRow, first as firstRow">
                                <td>{{log.module}}</td>
                                <td>{{log.date}}</td>
                                <td>
                                    <span *ngIf="log.description.length <= 1 else descriptionList">
                                        {{log.description?.length ? log.description[0] : ''}}
                                    </span>
                                    <ng-template #descriptionList>
                                        <ul class="ml-4">
                                            <li *ngFor="let logDescItem of log.description">
                                                {{logDescItem}}
                                            </li>
                                        </ul>
                                    </ng-template>
                                </td>
                            </tr>
                            <tr *ngIf="deployConfig?.trails.items && deployConfig?.trails.items.length <= 0">
                                <td colspan="4">
                                    <mat-label class="d-flex pt-2 justify-content-center">
                                        {{'LOCALIZATION.AUDIT.NO_RECORDS' | translate}}</mat-label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row no-gutters ">
            <button class="primary-button large-button ml-auto"
                (click)="deployConfigurations()">{{'LOCALIZATION.DEPLOYMENT_JOB.DEPLOY' | translate}}</button>
            <button class="primary-button large-button ml-2"
                (click)="discardDraftConfirmation()">{{'LOCALIZATION.DEPLOYMENT_JOB.DISCARD' | translate}}</button>
        </div>
    </ng-container>
    <ng-container *ngIf="deployData && deployData.deployments && deployData.deployments.length > 0">
        <div class="pos-relative">
            <span class="title-span fw-bold fs-16px">
                {{'LOCALIZATION.DEPLOYMENT_JOB.DEPLOYMEMENT_JOBS' | translate}}
            </span>
            <div class="d-flex flex-column deploy-table-container">
                <div class="mb-3">
                    <mat-checkbox (change)="toggleNightlyDeploymentList($event)" class="appear-enabled">
                        {{'LOCALIZATION.DEPLOYMENT_JOB.DEALER_CONFIGURATION_CHANGES' | translate}}
                    </mat-checkbox>
                </div>
                <div>
                    <div class="accordion-title-container">
                        <span>{{'LOCALIZATION.DEPLOYMENT_JOB.DEPLOYMENT_ID' | translate}}</span>
                        <span>{{'LOCALIZATION.DEPLOYMENT_JOB.DEPLOYMENT_SOURCE' | translate}}</span>
                        <span>{{'LOCALIZATION.DEPLOYMENT_JOB.START_TIME' | translate}}</span>
                        <span>{{'LOCALIZATION.DEPLOYMENT_JOB.END_TIME' | translate}}</span>
                        <span>{{'LOCALIZATION.DEPLOYMENT_JOB.DEPLOYMENT_STATUS' | translate}}</span>
                    </div>
                    <mat-accordion>
                        <ng-container *ngFor="let deploy of deployData.deployments">
                            <mat-expansion-panel
                                [disabled]="!deploymentEndStatus.includes(deploy.deploymentStatus) || !deploy.Jobs || (deploy.Jobs && deploy.Jobs.length == 0)"
                                [hideToggle]="!deploymentEndStatus.includes(deploy.deploymentStatus) || !deploy.Jobs || (deploy.Jobs && deploy.Jobs.length == 0)">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <span class="logs-status"
                                            (click)="showAuditLogs($event, deploy.deploymentId, deploy.comments)"
                                            [matTooltip]="'LOCALIZATION.DEPLOYMENT_JOB.AUDIT_LOG_TOOLTIP' | translate">{{deploy.deploymentId}}</span>
                                    </mat-panel-title>
                                    <mat-panel-title>
                                        {{deploy.source}}
                                    </mat-panel-title>
                                    <mat-panel-title>
                                        {{deploy.startTime}}
                                    </mat-panel-title>
                                    <mat-panel-title>
                                        {{ deploymentEndStatus.includes(deploy.deploymentStatus) ? deploy.endTime : ''
                                        }}
                                    </mat-panel-title>
                                    <mat-panel-title class="status-info">
                                        <span [ngClass]="{'deploy-status': deploy.showPlan}"
                                            (click)="deploy.showPlan && showPlan($event, deploy.plans)">{{deploy.deploymentStatus}}</span>
                                        <ng-container *ngIf="!deploymentEndStatus.includes(deploy.deploymentStatus)">
                                            <span *ngIf="!deploy.refreshSpinner" class='material-icons'
                                                matTooltip="Reload"
                                                (click)="reloadDeploymentItem($event, deploy)">refresh</span>
                                            <dd-spinner *ngIf="deploy.refreshSpinner"></dd-spinner>
                                        </ng-container>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <table class="table w-100" aria-describedby="rate-table"
                                    *ngIf="deploy.Jobs && deploy.Jobs.length > 0">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="pl-2">
                                                {{'LOCALIZATION.DEPLOYMENT_JOB.JOB_TYPE' | translate}}
                                            </th>
                                            <th scope="col" class="pl-2">
                                                {{'LOCALIZATION.DEPLOYMENT_JOB.START_TIME' | translate}}
                                            </th>
                                            <th scope="col" class="pl-2">
                                                {{'LOCALIZATION.DEPLOYMENT_JOB.END_TIME' | translate}}
                                            </th>
                                            <th scope="col" class="pl-2">
                                                {{'LOCALIZATION.DEPLOYMENT_JOB.STATUS' | translate}}
                                            </th>
                                            <th scope="col" class="pl-2">
                                                {{'LOCALIZATION.DEPLOYMENT_JOB.REPORT' | translate}}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let job of deploy.Jobs">
                                            <td>{{job.jobId}}</td>
                                            <td>{{job.startTime}}</td>
                                            <td>{{ deploymentEndStatus.includes(job.status) ? job.endTime : '' }}</td>
                                            <td>{{job.status}}</td>
                                            <td class="deploy-details">
                                                <ng-container
                                                    *ngIf="(!job.summaryReport || job.summaryReport == '' || job.summaryReport.length <= 0) else jobReport">
                                                    {{'LOCALIZATION.DEPLOYMENT_JOB.NOT_AVAILABLE' | translate}}
                                                </ng-container>
                                                <ng-template #jobReport>
                                                    <span (click)="showSummary(job)">
                                                        {{'LOCALIZATION.DEPLOYMENT_JOB.GET_SUMMARY' | translate}}
                                                    </span>
                                                    <span (click)="downloadDetail(job)">
                                                        {{'LOCALIZATION.DEPLOYMENT_JOB.DOWNLOAD_DETAIL' | translate}}
                                                    </span>
                                                </ng-template>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </mat-expansion-panel>
                        </ng-container>
                    </mat-accordion>
                </div>
            </div>
        </div>
    </ng-container>
</div>